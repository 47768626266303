<template>
  <div id="nav">
      <font-awesome-icon :icon="['far', 'clock']" class="icon" />
      <h1>Kreationsbyrån tidsloggning</h1>
  </div>
  <router-view />
</template>

<script>
import { Vue } from "vue-class-component";

export default class App extends Vue {}
</script>

<style lang="scss">
@import "./scss/main.scss";
</style>
