
import { Vue } from "vue-class-component";
import router from "@/router";
import http from "@/services/httpService";
import axios from "axios";
export default class Auth extends Vue {
  async created() {
    const code = this.$route.query.code;
    if (!code) router.replace("/login");
    const config = {
      headers: {
        "application/json": code,
      },
    };

    const {data} = await http.post("https://www.teamwork.com/launchpad/v1/token.json", {
      "code": code,
      "client_secret": "d9b035d809ba400486ffc7410d5ee2214f9e3842",
      "redirect_uri": "https://time-test.kbweb.se/auth",
      "client_id": "602c9f7ef8e420c29c02c0ac458b876b97410d5e",
    });
    const token = data.access_token;
    http.loginWithToken(token);
    router.push("/")
  }
}
