import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Auth from "@/views/Auth.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});



router.beforeEach((to, from, next) => {
  let isAuthenticated = false;
  const user = localStorage.getItem("kbweb-time-app-token");
  if (user) isAuthenticated = true;
  if (to.name !== "Login" && to.name !== "Auth" && !isAuthenticated)
    next({ name: "Login" });
  else next();
});

export default router;
