
import { Options, Vue } from "vue-class-component";
import http from "@/services/httpService";
import sort from "fast-sort";
import _ from "lodash";
import { Time, User, Project, Timelog } from "@/interfaces/interfaces";

@Options({
  components: {},
})
export default class Home extends Vue {
  private loading = false;
  private error = false;
  private projects: Array<Project> = [];
  private timelogs: Array<Timelog> = [];
  private users: Array<User> = [];
  private timeTotal: Time = { billed: 0, billable: 0, nonBillable: 0 }; // in hours;
  private currentUserId = 0;
  private month = this.currentMonth;

  async created() {
    try {
      this.update();
    } catch (error) {
      this.error = true;
    }
  }

  private async update() {
    this.resetState();
    await this.fetchData();
    this.calcTimeTotals();
    this.pruneInactiveProjects();
    this.pruneInactiveUsers();
    this.addSummaryUser();
    this.loading = false;
  }

  get currentMonth() {
    const year = new Date().getFullYear().toString();
    let month = (new Date().getMonth() + 1).toString();
    if (month.length < 2) month = `0${month}`;
    return `${year}-${month}`;
  }

  public totalTime() {
    const currentUser = this.users.find(
      (user) => user.id === this.currentUserId
    );
    if (!currentUser) {
      return this.timeTotal.nonBillable + this.timeTotal.billable;
    } else {
      return currentUser.timeTotal.nonBillable + currentUser.timeTotal.billable;
    }
  }

  public procentBillable() {
    const currentUser = this.users.find(
      (user) => user.id === this.currentUserId
    );
    if (!currentUser) {
      return (
        Math.round((this.timeTotal.billable / this.totalTime()) * 100) + "%"
      );
    } else {
      return (
        Math.round((currentUser.timeTotal.billable / this.totalTime()) * 100) +
        "%"
      );
    }
  }

  public procentNonBillable() {
    const currentUser = this.users.find(
      (user) => user.id === this.currentUserId
    );
    if (!currentUser) {
      return (
        Math.round((this.timeTotal.nonBillable / this.totalTime()) * 100) + "%"
      );
    } else {
      return (
        Math.round(
          (currentUser.timeTotal.nonBillable / this.totalTime()) * 100
        ) + "%"
      );
    }
  }

  get displayData() {
    const userIndex = this.users.findIndex(
      (user) => user.id === this.currentUserId
    );
    const data: Array<Project> = [
      ...this.users[userIndex].projects,
      {
        name: "Total",
        time: { ...this.users[userIndex].timeTotal },
        id: 1,
      },
    ];
    const prunedData = this.pruneDisplayData(data);
    if (prunedData.length) {
      return prunedData.slice(0, prunedData.length - 1);
    } else {
      return [];
    }
  }

  get getTotal() {
    const userIndex = this.users.findIndex(
      (user) => user.id === this.currentUserId
    );
    const data: Project = {
      name: "Total",
      time: { ...this.users[userIndex].timeTotal },
      id: 1,
    };
    return [data];
  }

  private resetState() {
    this.loading = true;
    this.users = [];
    this.timelogs = [];
    this.timeTotal = { billable: 0, nonBillable: 0, billed: 0 };
    this.projects = [];
  }

  private getQueryDates() {
    let startDate: Date | string = new Date(this.month);
    let endDate: Date | string = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + 1,
      0
    );
    startDate.setHours(2);
    endDate.setHours(2);

    startDate = startDate.toISOString().split("T")[0];
    endDate = endDate.toISOString().split("T")[0];
    return [startDate, endDate];
  }

  private pruneDisplayData(projects: Array<Project>) {
    let inactiveProjectIds: Array<number> = [];
    projects.forEach((project) => {
      const { billable, nonBillable } = project.time;
      if (billable + nonBillable === 0)
        inactiveProjectIds = [...inactiveProjectIds, project.id];
    });
    return projects.filter(
      (project) => !inactiveProjectIds.includes(project.id)
    );
  }

  private async fetchData() {
    const projectsPromise = this.fetchProjects();
    const timelogsPromise = this.fetchTimelogs();
    const usersPromise = this.fetchUsers();

    await Promise.all([projectsPromise, timelogsPromise, usersPromise]).then(
      (responses) => {
        this.pruneProjects(responses[0]);
        this.pruneTimelogs(responses[1]);
        this.pruneUsers(responses[2]);
      }
    );
  }

  private async fetchProjects() {
    try {
      const { data } = await http.get(`/projects.json`);
      return data.projects;
    } catch (error) {
      console.log("Error fetching projects, ", error);
    }
  }

  private async fetchTimelogs() {
    const [startDate, endDate] = this.getQueryDates();
    try {
      const { data } = await http.get(`/time.json`, {
        params: {
          pageSize: 500,
          startDate: startDate,
          endDate: endDate,
        },
      });
      
      return data.timelogs;
    } catch (error) {
      console.log("Error fetching timelogs, ", error);
    }
  }

  private async fetchUsers() {
    try {
      const { data } = await http.get(`/people.json`);
      return data.people;
    } catch (error) {
      console.log("Error fetching users, ", error);
    }
  }

  private pruneProjects(rawData: Array<any>) {
    rawData.forEach((project) => {
      this.projects = [
        ...this.projects,
        {
          id: project.id,
          name: project.name,
          time: { nonBillable: 0, billable: 0, billed: 0 },
        },
      ];
    });
  }

  private pruneUsers(rawData: Array<any>) {
    rawData.forEach((user) => {
      this.users = [
        ...this.users,
        {
          id: user.id,
          name: user.firstName,
          projects: _.cloneDeep(this.projects),
          timeTotal: { nonBillable: 0, billable: 0, billed: 0 },
        },
      ];
    });
  }

  private pruneTimelogs(rawData: Array<any>) {
    rawData.forEach((timelog) => {
      this.timelogs = [
        ...this.timelogs,
        {
          loggedAt: timelog.timeLogged,
          duration: timelog.minutes,
          billable: timelog.billable,
          projectId: timelog.projectId,
          userId: timelog.userId,
          billed: timelog.projectBillingInvoiceId !== null,
        },
      ];
    });
  }

  private pruneInactiveProjects() {
    let inactiveProjectIds: Array<number> = [];
    this.projects.forEach((project) => {
      const { billable, nonBillable } = project.time;
      if (billable + nonBillable === 0)
        inactiveProjectIds = [...inactiveProjectIds, project.id];
    });
    this.projects = this.projects.filter(
      (project) => !inactiveProjectIds.includes(project.id)
    );
    this.sortProjects("billable", "desc");
  }

  private pruneInactiveUsers() {
    let inactiveUserIds: Array<number> = [];
    this.users.forEach((user) => {
      if (user.timeTotal.billable + user.timeTotal.nonBillable === 0)
        inactiveUserIds = [...inactiveUserIds, user.id];
    });
    this.users = this.users.filter(
      (user) => !inactiveUserIds.includes(user.id)
    );
  }

  private sortProjects(key: keyof Time, direction: "asc" | "desc") {
    this.projects =
      direction === "asc"
        ? sort(this.projects).asc((project) => project.time[key])
        : sort(this.projects).desc((project) => project.time[key]);
  }

  private calcTimeTotals() {
    this.timelogs.forEach((timelog) => {
      const projectIndex = this.projects.findIndex(
        (project) => project.id === timelog.projectId
      );
      const userIndex = this.users.findIndex(
        (user) => user.id === timelog.userId
      );
      if (userIndex != -1 && projectIndex != -1) {
        const loggedHours = timelog.duration / 60;
        if (timelog.billable === true) {
          this.timeTotal.billable += loggedHours;
          this.projects[projectIndex].time.billable += loggedHours;
          this.users[userIndex].projects[
            projectIndex
          ].time.billable += loggedHours;
          this.users[userIndex].timeTotal.billable += loggedHours;
          if (timelog.billed) {
            this.timeTotal.billable += loggedHours;
            this.projects[projectIndex].time.billed += loggedHours;
            this.users[userIndex].projects[
              projectIndex
            ].time.billed += loggedHours;
            this.users[userIndex].timeTotal.billed += loggedHours;
          }
        } else {
          this.timeTotal.nonBillable += loggedHours;
          this.projects[projectIndex].time.nonBillable += loggedHours;
          this.users[userIndex].projects[
            projectIndex
          ].time.nonBillable += loggedHours;
          this.users[userIndex].timeTotal.nonBillable += loggedHours;
        }
      }
    });
  }

  private addSummaryUser() {
    let projects: Array<Project> = [];
    let timeTotal = { billable: 0, billed: 0, nonBillable: 0 };
    this.users.forEach((user) => {
      projects = [
        ...projects,
        { name: user.name, time: { ...user.timeTotal }, id: 0 },
      ];
      timeTotal = {
        billed: timeTotal.billed + user.timeTotal.billed,
        billable: timeTotal.billable + user.timeTotal.billable,
        nonBillable: timeTotal.nonBillable + user.timeTotal.nonBillable,
      };
    });
    this.users = [
      {
        name: "Välj person",
        id: 0,
        projects: projects,
        timeTotal: timeTotal,
      },
      {
        name: "Total",
        id: 1337,
        projects: this.projects,
        timeTotal: this.timeTotal,
      },
      ...this.users,
    ];
  }
}
