import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
// axios.defaults.headers.common["Authorization"] = process.env.VUE_APP_API_KEY;

const token = localStorage.getItem(
  "kbweb-time-app-token"
);
if (token) axios.defaults.headers.get["Authorization"] = `Bearer ${token}`

const loginWithToken = (token: string) => {
  localStorage.setItem("kbweb-time-app-token",token)
  axios.defaults.headers.get["Authorization"] = `Bearer ${token}`
}
const http = {
  get: axios.get,
  post: axios.post,
  loginWithToken,
};

export default http;
